// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-heroes-pages-hero-template-jsx": () => import("./../src/components/heroes/pages/hero-template.jsx" /* webpackChunkName: "component---src-components-heroes-pages-hero-template-jsx" */),
  "component---src-components-heroes-pages-important-template-jsx": () => import("./../src/components/heroes/pages/important-template.jsx" /* webpackChunkName: "component---src-components-heroes-pages-important-template-jsx" */),
  "component---src-pages-75-jsx": () => import("./../src/pages/75.jsx" /* webpackChunkName: "component---src-pages-75-jsx" */),
  "component---src-pages-cherepovets-details-jsx": () => import("./../src/pages/cherepovets-details.jsx" /* webpackChunkName: "component---src-pages-cherepovets-details-jsx" */),
  "component---src-pages-cherepovets-jsx": () => import("./../src/pages/cherepovets.jsx" /* webpackChunkName: "component---src-pages-cherepovets-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-important-order-jsx": () => import("./../src/pages/important/order.jsx" /* webpackChunkName: "component---src-pages-important-order-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-order-jsx": () => import("./../src/pages/order.jsx" /* webpackChunkName: "component---src-pages-order-jsx" */),
  "component---src-pages-songs-jsx": () => import("./../src/pages/songs.jsx" /* webpackChunkName: "component---src-pages-songs-jsx" */),
  "component---src-pages-start-jsx": () => import("./../src/pages/start.jsx" /* webpackChunkName: "component---src-pages-start-jsx" */)
}

